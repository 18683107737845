<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p style="font-size:26px; font-weight:bold; margin-bottom:4px;">회원가입</p>
                    <p style="font-size:11px;" class="mb-0">포켓몬카드 DB/커뮤니티 너정다</p>
                </div>
            </div>

            <!-- 본문 -->
            <v-sheet class="py-8 pb-14 rounded-15">
                <v-sheet
                    width="460"
                    class="mx-auto"
                >
                    <!-- 본문 제목 -->
                    <p
                        class="mt-4 text-center font-weight-bold"
                        style="font-size:20px;"
                    >
                        너정다 회원가입
                    </p>

                    <!-- 입력란 -->
                    <v-sheet class="mt-10">
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            v-model="user_id"
                            color="#615DFA"
                            label="아이디"
                            persistent-placeholder
                            @change="$v.user_id.$touch()"
                            @blur="$v.user_id.$touch()"
                            :error-messages="user_id_Errors"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            v-model="password"
                            color="#615DFA"
                            label="비밀번호"
                            persistent-placeholder
                            type="password"
                            @change="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                            :error-messages="password_Errors"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            v-model="password_check"
                            color="#615DFA"
                            label="비밀번호 확인"
                            persistent-placeholder
                            type="password"
                            @change="$v.password_check.$touch()"
                            @blur="$v.password_check.$touch()"
                            :error-messages="password_check_Errors"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            v-model="nickname"
                            color="#615DFA"
                            label="닉네임"
                            persistent-placeholder
                            @change="$v.nickname.$touch()"
                            @blur="$v.nickname.$touch()"
                            :error-messages="nickname_Errors"
                        >
                        </v-text-field>
                        <v-sheet class="d-flex align-center mx-4">
                            <v-text-field
                                outlined
                                v-model="email"
                                color="#615DFA"
                                label="이메일"
                                persistent-placeholder
                                hide-details
                            >
                            </v-text-field>
                            <p class="text-subtitle-1 mx-4 mb-1 font-weight-bold" style="color:#615DFA;">@</p>
                            <v-select
                                style="width:266px;"
                                outlined
                                color="#615DFA"
                                :items="select_list.email"
                                label="도메인 선택"
                                persistent-placeholder
                                v-model="email_type"
                                hide-details
                            ></v-select>
                        </v-sheet>

                        <div class="d-flex align-end justify-center mb-1 mr-1">
                            <v-checkbox
                                hide-details
                                v-model="email_agree"
                            ></v-checkbox>
                            <p
                                style="font-size:15px; font-weight:600; margin-left:-4px;"
                                class="mb-0"
                            >
                                <span style="color:#F8468D;">1,000P 받고</span> 이메일 수신동의
                            </p>
                        </div>
                        <p
                            class="mb-5 text-center"
                            style="font-size:11px;"
                        >
                            신규 카드 발매소식, 포인트몰 이벤트 정보 등을 이메일로 알려드립니다.
                        </p>
                        
                    </v-sheet>

                    <!-- Submit -->
                    <v-sheet class="d-flex justify-center">
                        <v-btn
                            @click="submit()"
                            class="font-weight-bold rounded-10"
                            style="font-size:18px;"
                            color="#615DFA"
                            dark
                            depressed
                            width="173"
                            height="56"
                        >
                            가입하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="px-6 pt-3"
        >
            <!-- 상단 -->
            <div class="d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p style="font-size:16px; font-weight:bold; margin-bottom:10px;">회원가입</p>
                </div>
            </div>

            <!-- 본문 -->
            <v-sheet class="py-8 rounded-15">
                <v-sheet
                    class="mx-auto"
                >
                    <!-- 입력란 -->
                    <v-sheet>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            dense
                            v-model="user_id"
                            color="#615DFA"
                            label="아이디"
                            persistent-placeholder
                            @change="$v.user_id.$touch()"
                            @blur="$v.user_id.$touch()"
                            :error-messages="user_id_Errors"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            dense
                            v-model="password"
                            color="#615DFA"
                            label="비밀번호"
                            persistent-placeholder
                            type="password"
                            @change="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                            :error-messages="password_Errors"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            dense
                            v-model="password_check"
                            color="#615DFA"
                            label="비밀번호 확인"
                            persistent-placeholder
                            type="password"
                            @change="$v.password_check.$touch()"
                            @blur="$v.password_check.$touch()"
                            :error-messages="password_check_Errors"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14 mb-3"
                            outlined
                            dense
                            v-model="nickname"
                            color="#615DFA"
                            label="닉네임"
                            persistent-placeholder
                            @change="$v.nickname.$touch()"
                            @blur="$v.nickname.$touch()"
                            :error-messages="nickname_Errors"
                        >
                        </v-text-field>
                        <v-sheet class="d-flex align-center mx-4">
                            <v-text-field
                                outlined
                                dense
                                v-model="email"
                                color="#615DFA"
                                label="이메일"
                                persistent-placeholder
                                hide-details
                            >
                            </v-text-field>
                            <p class="text-subtitle-1 mx-4 mb-1 font-weight-bold" style="color:#615DFA;">@</p>
                            <v-select
                                style="width:266px;"
                                outlined
                                dense
                                color="#615DFA"
                                :items="select_list.email"
                                label="도메인 선택"
                                persistent-placeholder
                                v-model="email_type"
                                hide-details
                            ></v-select>
                        </v-sheet>
                        <div class="d-flex align-end justify-center mt-1 mb-1 mr-1">
                            <v-checkbox
                                dense
                                hide-details
                                v-model="email_agree"
                            ></v-checkbox>
                            <p
                                style="font-size:12px; font-weight:600; margin-left:-4px;"
                                class="mb-1"
                            >
                                <span style="color:#F8468D;">1,000P 받고</span> 이메일 수신동의
                            </p>
                        </div>
                        <p
                            class="mb-5 text-center"
                            style="font-size:9px;"
                        >
                            신규 카드 발매소식, 포인트몰 이벤트 정보 등을 이메일로 알려드립니다.
                        </p>
                    </v-sheet>

                    <!-- Submit -->
                    <v-sheet class="d-flex justify-center">
                        <v-btn
                            @click="submit()"
                            class="font-weight-bold rounded-10"
                            style="font-size:13px;"
                            color="#615DFA"
                            dark
                            depressed
                            width="90"
                            height="30"
                        >
                            가입하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
// 검증
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength, helpers } from 'vuelidate/lib/validators'

// 특수문자, 공백 확인
const noSpecialCharsOrSpaces = helpers.regex('noSpecialCharsOrSpaces', /^[^\s!@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/)
const noKorean = helpers.regex('noKorean', /^[^\uAC00-\uD7AF]*$/)

export default {
    mixins: [validationMixin],

    validations: {
        user_id: {
            required,
            minLength: minLength(6),
            noKorean,
            noSpecialCharsOrSpaces,
        },

        password: {
            required,
            minLength: minLength(6)
        },

        password_check: {
            required,
            sameAsPassword: sameAs('password')
        },

        nickname: {
            required,
            minLength: minLength(2),
            noSpecialCharsOrSpaces,
        }
    },

    data: () => ({
        // 입력값
        user_id:"",
        password:"",
        password_check:"",
        nickname:"",
        email:"",
        email_type: "",
        email_agree: true,

        // 중복 체크용 목록
        user_id_list: [],
        nickname_list: [],
        email_list: [],

        // 이메일 선택목록
        select_list: {
            email: [
                'naver.com',
                'gmail.com',
                'hanmail.net',
                'nate.com',
                'yahoo.co.kr',
                'empase.com',
                'hotmail.com',
                'dreamwiz.com',
                'freechal.com',
                'lycos.co.kr',
                'korea.com',
                'hanmir.com',
                'paran.com'
            ]
        },

        forbiddenWords : [
            "섹스",
            "보지",
            "자지",
            "한남",
            "한녀",
        ]
    }),

    computed: {
        // 아이디 Error 문구
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push('아이디를 입력해주세요')
            !this.$v.user_id.minLength && errors.push('아이디는 최소 6자 이상이여야합니다')
            !this.$v.user_id.noKorean && errors.push('아이디에는 한글이 입력될 수 없습니다')
            !this.$v.user_id.noSpecialCharsOrSpaces && errors.push('아이디에는 공백 또는 특수문자가 입력될 수 없습니다')
            this.user_id_list.some(user_id => user_id.toLowerCase() === this.$v.user_id.$model.toLowerCase()) && errors.push('입력하신 아이디는 이미 존재합니다.')
            this.$v.user_id == 'Pokémon'  && errors.push('Pokémon을 아이디로 할 수 없습니다')
            this.$v.user_id == 'Pokemon'  && errors.push('Pokemon을 아이디로 할 수 없습니다')
            return errors
        },

        // 비밀번호 Error 문구
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            !this.$v.password.minLength && errors.push('패스워드는 최소 6자 이상이여야합니다')
            return errors
        },

        // 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('비밀번호 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('비밀번호 확인이 같지 않습니다')
            return errors
        },

        // 닉네임 Error 문구
        nickname_Errors () {
            const errors = []
            if (!this.$v.nickname.$dirty) return errors
            !this.$v.nickname.required && errors.push('닉네임을 입력해주세요')
            !this.$v.nickname.minLength && errors.push('닉네임은 최소 2자 이상이여야합니다')
            !this.$v.nickname.noSpecialCharsOrSpaces && errors.push('닉네임에는 공백 또는 특수문자가 입력될 수 없습니다')
            this.nickname_list.includes(this.$v.nickname.$model) && errors.push('입력하신 닉네임은 이미 존재합니다.')
            this.containsForbiddenWord(this.nickname) && errors.push('닉네임에 금지어가 포함되어있습니다.')
            return errors
        }
    },

    created () {
        // 기존 아이디 검색
        this.loadUserIdList()

        // 기존 닉네임 검색
        this.loadNicknameList()

        // 기존 이메일 검색
        this.loadEmailList()
    },

    methods: {
        // 주어진 문자열에 금지어가 포함되어 있는지 확인하는 함수
        containsForbiddenWord(str) {
            return this.forbiddenWords.some(forbiddenWord => str.includes(forbiddenWord))
        },

        // 기존 아이디 검색
        async loadUserIdList(){
            await this.$http.get('/api/auth/join/user_id_search')
            .then((res) => {
                let list = []
                res.data.forEach(element => {list.push(element.user_id)})
                this.user_id_list = list
            })
        },

        // 기존 닉네임 검색
        async loadNicknameList(){
            await this.$http.get('/api/auth/join/nickname_search')
            .then((res) => {
                var list = []
                res.data.forEach(element => {list.push(element.nickname)})
                this.nickname_list = list
            })
        },

        // 기존 이메일 검색
        async loadEmailList(){
            this.$http.get('/api/auth/join/email_search')
            .then((res) => {
                var list = []
                res.data.forEach(element => {list.push(element.email)})
                this.email_list = list
            })
        },

        // 가입하기
        submit: _.debounce(async function() {
            
            // 입력값 기본 검증
            this.$v.$touch()

            // 기존 아이디 검색
            await this.loadUserIdList()

            // 기존 닉네임 검색
            await this.loadNicknameList()

            // 기존 이메일 검색
            await this.loadNicknameList()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // 추가 검증
                if(this.user_id_list.some(user_id => user_id.toLowerCase() === this.user_id.toLowerCase()))
                {
                    alert("입력하신 아이디는 이미 존재합니다.")
                }
                else if(this.nickname_list.includes(this.nickname))
                {
                    alert('입력하신 닉네임은 이미 존재합니다.')
                }
                else if(this.email == '' || this.email_type == '')
                {
                    alert("이메일을 정상적으로 입력해주세요")
                }
                else if(this.email_list.includes(this.email + '@' + this.email_type))
                {
                    alert("입력하신 이메일은 이미 가입하신 이메일입니다.")
                }
                else
                {
                    this.$http.post('/api/auth/join/insert', {
                        params: {
                            user_id:this.user_id,
                            password:this.password,
                            nickname:this.nickname,
                            email:this.email + '@' + this.email_type,
                            email_agree: this.email_agree,
                            status: "미인증"
                        }
                    }).then(async () => {
                        alert("회원가입을 성공적으로 완료하였습니다.\n작성하신 이메일로 인증 메일이 발송되었습니다.\n메일에서 '메일 인증'버튼을 누르시면 회원가입이 완료됩니다.");
                        this.$http.post('/api/auth/email/send', {
                            params: {
                                user_id: this.user_id,
                                email: this.email + '@' + this.email_type
                            }
                        })

                        if(this.email_agree){
                            // 포인트 내역 기록
                            await this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                                params: {
                                    user_id: this.user_id,
                                    title: "첫 이메일 수신동의",
                                    menu: '회원가입',
                                    activity: '첫 이메일 수신동의',
                                    pay_point: 1000,
                                }
                            }).then((res) => {
                                if(res.data.affectedRows){
                                    alert("첫 이메일 수신동의로 1,000포인트가 지급되었습니다.")
                                }
                            })

                            // 포인트 지급
                            await this.$http.post('/api/mypage/address/point/plus', {
                                params: {
                                    point: 1000,
                                    user_id: this.user_id
                                }
                            })

                            // 첫 수신동의 체크
                            await this.$http.post('/api/user/update/email_agree_first', {
                                params: {
                                    user_id: this.user_id
                                }
                            })

                            // DB 알림
                            this.$dbAlarm(
                                "회원가입",
                                this.nickname,
                                this.user_id
                            )
                    
                            window.location.href="/"
                        }
                        else
                        {
                            // DB 알림
                            this.$dbAlarm(
                                "회원가입",
                                this.nickname,
                                this.user_id
                            )
                    
                            window.location.href="/"
                        }
                    })
                }   
            } 
        }, 500)
    }
}
</script>
<style scoped>
/* v-text-field, v-select */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
  border-radius: 10px;
}

.v-text-field--outlined >>> label {
    color: #615DFA;
    font-size:16px;
    font-weight:400;
}

::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}
</style>