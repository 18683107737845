<template>
    <div>
        <!-- PC -->
        <v-sheet v-if="!$vuetify.breakpoint.mobile" class="pb-16 rounded-10">
            <p class="pt-6 font-weight-bold text-center text-h5 grey--text text--darken-2">로그인</p>
            <v-divider class="mx-4"></v-divider>
            <v-sheet class="mt-10">
                <v-sheet
                    width="460"
                    class="mx-auto"
                >
                    <v-img
                        class="mx-auto mt-10"
                        :src="require('@/assets/logos/vertical_black.svg')"
                        contain
                        width="180"
                    />
                    <p class="mt-4 text-center font-weight-bold text-caption primary--text">
                        포켓몬카드게임 한국 커뮤니티 '너로정했다'에<br/>
                        오신 것을 진심으로 환영합니다
                    </p>
                    <v-card-text>
                        <v-text-field
                            class="mx-14"
                            outlined
                            flat
                            dense
                            v-model="user_id"
                            color="primary"
                            placeholder="아이디"
                            @change="$v.user_id.$touch()"
                            @blur="$v.user_id.$touch()"
                            :error-messages="user_id_Errors"
                            @keyup.enter="submit()"
                        >
                        </v-text-field>
                        <v-text-field
                            class="mx-14"
                            outlined
                            flat
                            dense
                            v-model="password"
                            color="primary"
                            placeholder="비밀번호"
                            type="password"
                            @change="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                            :error-messages="password_Errors"
                            @keyup.enter="submit()"
                        >
                        </v-text-field>
                    </v-card-text>
                    <v-card-text class="px-16 pt-2">
                        <v-btn
                            class="font-weight-bold rounded-10"
                            color="primary"
                            dark
                            width="100%"
                            large
                            depressed
                            @click="submit()"
                        >
                            로그인
                        </v-btn>
                    </v-card-text>

                    <!-- 소셜 로그인 -->
                    <v-card-text class="d-flex justify-center align-center py-0 px-16">
                        <v-divider></v-divider>
                        <font class="px-4">소셜 로그인</font>
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-card-text class="d-flex justify-center">
                        <!-- <v-btn
                            small
                            color="#2DB400"
                            depressed
                            dark
                            fab
                            @click="$buttonAlert('준비중입니다.')"
                        >
                            <v-icon x-large>mdi-alpha-n</v-icon>
                        </v-btn> -->
                        <v-btn
                            small
                            color="#F7E600"
                            depressed
                            dark
                            class="mx-10"
                            fab
                            @click="social_kakao()"
                        >
                            <v-icon>mdi-chat</v-icon>
                        </v-btn>
                        <!-- <v-btn
                            small
                            color="#4285F4"
                            depressed
                            dark
                            fab
                            @click="$buttonAlert('준비중입니다.')"
                        >
                            <v-icon>mdi-google</v-icon>
                        </v-btn> -->
                    </v-card-text>

                    <!-- 하단 버튼 -->
                    <v-card-text class="d-flex justify-center">
                        <v-btn
                            to="/auth/findid"
                            small
                            text
                            color="grey darken-1"
                            class="mr-1"
                        >
                            아이디 찾기
                        </v-btn>
                        <v-btn
                            to="/auth/findpassword"
                            small
                            text
                            color="grey darken-1"
                            class="mr-1"
                        >
                            비밀번호 찾기
                        </v-btn>
                        <v-btn
                            to="/auth/joinagree"
                            small
                            text
                            color="grey darken-1"
                        >
                            회원가입
                        </v-btn>
                    </v-card-text>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <div v-if="$vuetify.breakpoint.mobile" class="px-6 pt-3">
            <p
                style="font-size:16px;"
                class="font-weight-bold mb-2"
            >
                너정다 로그인
            </p>
            <v-sheet
                class="px-16 pt-7 pb-3 rounded-15"
            >
                <v-text-field
                    class="rounded-10"
                    outlined
                    flat
                    dense
                    label="아이디"
                    v-model="user_id"
                    color="primary"
                    placeholder="아이디"
                    persistent-placeholder
                    @change="$v.user_id.$touch()"
                    @blur="$v.user_id.$touch()"
                    :error-messages="user_id_Errors"
                    @keyup.enter="submit()"
                ></v-text-field>
                <v-text-field
                    style="margin-top:-4px;"
                    class="rounded-10"
                    outlined
                    flat
                    dense
                    v-model="password"
                    color="primary"
                    label="비밀번호"
                    placeholder="비밀번호"
                    persistent-placeholder
                    type="password"
                    @change="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    :error-messages="password_Errors"
                    @keyup.enter="submit()"
                >
                </v-text-field>
                
                <div
                    class="d-flex justify-center"
                >
                    <v-btn
                        width="74"
                        height="32"
                        class="font-weight-bold rounded-10 mr-2"
                        color="#615DFA"
                        dark
                        depressed
                        @click="submit()"
                    >
                        로그인
                    </v-btn>
                    <v-btn
                        width="74"
                        height="32"
                        class="font-weight-bold rounded-10"
                        color="#615DFA"
                        dark
                        depressed
                        @click="$router.go(-1)"
                    >
                        취소
                    </v-btn>
                </div>

                <p
                    class="mt-4"
                    style="font-size:13px; font-weight:500; text-align:center;"
                    @click="$router.push('/auth/findpassword')"
                >
                    비밀번호를 잊어버렸나요?
                </p>
            </v-sheet>

           <p
                style="font-size:12px; font-weight:bold; text-align:center; margin:10px 0;"
            >
                소셜 계정으로 로그인
            </p>

            <v-sheet
                class="rounded-15"
            >
                <v-card-text class="d-flex justify-center rounded-15">
                    <!-- <v-btn
                        small
                        color="#2DB400"
                        depressed
                        dark
                        fab
                        @click="$buttonAlert('준비중입니다.')"
                    >
                        <v-icon x-large>mdi-alpha-n</v-icon>
                    </v-btn> -->
                    <v-btn
                        small
                        color="#F7E600"
                        depressed
                        dark
                        class="mx-10"
                        fab
                        @click="social_kakao()"
                    >
                        <v-icon>mdi-chat</v-icon>
                    </v-btn>
                    <!-- <v-btn
                        small
                        color="#4285F4"
                        depressed
                        dark
                        fab
                        @click="$buttonAlert('준비중입니다.')"
                    >
                        <v-icon>mdi-google</v-icon>
                    </v-btn> -->
                </v-card-text>
            </v-sheet>

            <!-- 하단 버튼 -->
            <div
                class="d-flex justify-center mt-5 mb-1"
            >
                <v-btn
                    width="90"
                    height="32"
                    class="font-weight-bold rounded-10"
                    color="#615DFA"
                    dark
                    depressed
                    to="/auth/joinagree"
                >
                    회원가입
                </v-btn>
            </div>

            <p
                style="font-size:12px; color:#23D2E2; text-align:center;"
            >
                아이디/닉네임/이메일만 입력하면 가입완료!
            </p>
        </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import GradeCheck from '@/components/GradeCheck.js'

export default {
    mixins: [validationMixin],

    validations: {
        user_id: {
            required
        },
        password: {
            required
        }
    },

    data: () => ({
        user_id: "",
        password: ""
    }),

    computed: {
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push('아이디를 입력해주세요')
            return errors
        },
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            return errors
        }
    },

    methods: {
        ...mapMutations(['login']),

        submit(){
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.$http.post("/api/auth/login", {
                    params: {
                        user_id: this.user_id,
                        password:this.password
                    }
                }).then((res) => {
                    if(!res.data[0]){
                        this.$http.post("/api/auth/login/temp_password/check", {
                            params: {
                                user_id: this.user_id,
                                password: this.password
                            }
                        }).then((res) => {
                            if(res.data.length){
                                // 로그인
                                this.$http.post("/api/auth/login/specific", {
                                    params: {
                                        user_id: this.user_id,
                                    }
                                }).then((res2) => {
                                    if(res2.data[0].status != "인증완료"){
                                        alert("이메일 인증이 완료되지 않은 아이디입니다,\n이메일 인증을 완료 후 로그인해주세요.")
                                    }else{
                                        // 등급 Check
                                        GradeCheck.compare(this.user_id, 'fromCreated', 0)
                                        .then(() => {
                                            this.login(res2.data[0])
                                            this.$lastVisit(this.user_id)
                                            this.$router.go(-1)
                                        })
                                    }
                                })
                            }else{
                                alert("아이디 또는 비밀번호가 잘못되었습니다.")
                            }
                        })
                    }else{
                        this.$http.post("/api/auth/login/specific", {
                            params: {
                                user_id: this.user_id,
                            }
                        }).then((res2) => {
                            if(res2.data[0].status != "인증완료"){
                                alert("이메일 인증이 완료되지 않은 아이디입니다,\n이메일 인증을 완료 후 로그인해주세요.")
                            }else{
                                // 등급 Check
                                GradeCheck.compare(this.user_id, 'fromCreated', 0)
                                .then(() => {
                                    this.login(res2.data[0])
                                    this.$lastVisit(this.user_id)
                                    this.$router.go(-1)
                                })
                            }
                        })
                    }
                })
            }
        },

        // 카카오 로그인 (1)
        social_kakao(){
            if (!window.Kakao.isInitialized()) {
                window.Kakao.init('aad57776cc6978d3ad8445fa572c2f52')
            }

            if (window.Kakao.Auth.getAccessToken()) {
                window.Kakao.API.request({
                    url: '/v1/user/unlink',
                    success: function (response) {
                        console.log(response)
                    },
                    fail: function (error) {
                        console.log(error)
                    }
                })
                window.Kakao.Auth.setAccessToken(undefined)
            }

            window.Kakao.Auth.login({
                success: () => {
                    window.Kakao.API.request({
                        url: '/v2/user/me',
                        data: {
                            property_keys: ["kakao_account.profile", "kakao_account.email"]
                        },
                        success: async (response) => {
                            this.kakaoLogin(response)
                        },
                        fail: function (error) {
                            console.log(error)
                        },
                    })
                },
                fail: function (error) {
                    console.log(error)
                },
            })
        },

        // 카카오 로그인 (2)
        kakaoLogin(response){
            // 만약 기존에 카카오로그인한 계정 확인
            this.$http.post("/api/auth/login", {
                params: {
                    user_id: "kakao_" + response.kakao_account.email.split('@')[0],
                    password: response.id,
                }
            }).then((res) => {
                // 있을 경우 (그냥 로그인)
                if(res.data.length)
                {
                    const KakaoUserInfo = {
                        user_id: "kakao_" + response.kakao_account.email.split('@')[0],
                        nickname: res.data[0].nickname,
                        email: response.kakao_account.email,
                        profile_image: response.kakao_account.profile.thumbnail_image_url,
                        type: "사용자",
                        status: "인증완료",
                        created: new Date()
                    }

                    this.login(KakaoUserInfo)
                    this.$router.push("/")
                }

                // 없을 경우 (회원정보 입력후 로그인)
                else
                {
                    this.$http.post('/api/auth/join/insert', {
                        params: {
                            user_id: "kakao_" + response.kakao_account.email.split('@')[0],
                            password: response.id,
                            nickname: response.kakao_account.profile.nickname,
                            email: response.kakao_account.email,
                            email_agree: 0,
                            status: "인증완료",
                        }
                    }).then((res) => {
                        const KakaoUserInfo = {
                            user_id: "kakao_" + response.kakao_account.email.split('@')[0],
                            nickname: response.kakao_account.profile.nickname,
                            email: response.kakao_account.email,
                            profile_image: response.kakao_account.profile.thumbnail_image_url,
                            type: "사용자",
                            status: "인증완료",
                            created: new Date()
                        }

                        this.login(KakaoUserInfo)
                        this.$router.push("/")
                    })
                }
            })
        },
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}
</style>