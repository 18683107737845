<template>
    <v-sheet
        class="px-4 rounded-10"
        height="900"
    >
        <!-- 제목 -->
        <p class="pt-6 font-weight-bold text-center text-h5 grey--text text--darken-2">
            이메일 인증
        </p>

        <!-- 구분선 -->
        <v-divider class="mx-4 mb-14"></v-divider>
        
        <!-- 인증 실패시 -->
        <v-sheet
            v-if="!cert"
            class="text-center"
        >
            <!-- 아이콘 -->
            <v-icon
                size="80"
                color="red"
                class="ml-3"
            >
                mdi-email-alert-outline
            </v-icon>

            <!-- 문구 -->
            <p class="my-4 font-weight-bold grey--text">
                이메일 인증코드가 만료되었거나<br/>
                이미 인증을 완료된 계정입니다.
            </p>

            <p class="mb-6 text-body-2 font-weight-bold red--text">
                반복될 시 cm@icu.gg로 문의바랍니다.
            </p>

            <!-- 로그인 버튼 -->
            <v-btn
                width="240"
                class="rounded-10"
                color="primary"
                dark
                large
                depressed
                to="/auth/login"
            >
                로그인 하러가기
            </v-btn>
        </v-sheet>

        <!-- 인증 성공시 -->
        <v-sheet
            v-if="cert"
            class="text-center"
        >
            <!-- 아이콘 -->
            <v-icon
                size="80"
                color="primary"
            >
                mdi-email-check-outline
            </v-icon>

            <!-- 문구 -->
            <p class="mt-4 font-weight-bold grey--text text--darken-2">
                이메일 인증이 완료되었습니다.<br/>
                회원가입을 축하합니다.
            </p>
            <p class="mb-6 text-body-2 font-weight-bold primary--text">
                회원님께 100포인트가 지급되었습니다.
            </p>

            <!-- 로그인 버튼 -->
            <v-btn
                width="240"
                class="rounded-10"
                color="primary"
                dark
                large
                depressed
                to="/auth/login"
            >
                로그인 하러가기
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        cert: false
    }),

    mounted(){
        // 이메일 인증 check
        this.$http.post('/api/auth/email/certification/check', {
            params: {
                user_id: this.$route.query.user_id,
                serial: this.$route.query.serial
            }
        }).then((res) => {
            // 값이 없을을 경우
            if(!res.data.length){
                this.cert = false
            // 값이 있을 경우
            }else{
                // 유저 status '인증완료'로 Update
                this.$http.post('/api/auth/email/status/update', {
                    params: {
                        user_id: this.$route.query.user_id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.cert = true

                        // 포인트 내역 기록
                        this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                            params: {
                                user_id: this.$route.query.user_id,
                                title: "회원가입을 축하합니다",
                                menu: '회원가입',
                                activity: '이메일인증',
                                pay_point: 100,
                            }
                        })

                        // 이메일 인증 완료한 값 destroy
                        this.$http.post('/api/auth/email/certification/destroy', {
                            params: {
                                user_id: this.$route.query.user_id,
                                serial: this.$route.query.serial
                            }
                        })
                    }
                })
            }
        })
    }
}
</script>