<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p style="font-size:26px; font-weight:bold; margin-bottom:4px;">회원가입</p>
                    <p style="font-size:11px;" class="mb-0">포켓몬카드 DB/커뮤니티 너정다</p>
                </div>
            </div>

            <!-- 본문 -->
            <v-sheet class="py-8 pb-14 rounded-15">
                <v-sheet
                    width="460"
                    class="mx-auto"
                >
                    <v-img
                        class="mx-auto mt-10"
                        :src="require('@/assets/logos/vertical_black.svg')"
                        contain
                        width="180"
                    />
                    <p class="mt-5 text-center" style="font-size:16px;">
                        포켓몬카드게임 한국 커뮤니티 '너로정했다(너정다)'에<br/>
                        오신 것을 진심으로 환영합니다
                    </p>
                    <p class="mt-8 text-center font-weight-bold" style="color:#615DFA; font-size:16px;">
                        필수 이용약관 확인 후 동의해주시기 바랍니다
                    </p>
                    <v-card-text class="mt-2 px-4">
                        <v-sheet class="d-flex mx-14">
                            <v-checkbox
                                style="padding-top:3px !important"
                                class="ma-0 pa-0"
                                color="primary"
                                v-model="rule"
                                :error-messages="rule_Errors"
                                label="너정다 이용약관"
                                required
                                @change="$v.rule.$touch()"
                                @blur="$v.rule.$touch()"
                            ></v-checkbox>
                            <v-spacer></v-spacer>
                            <router-link to="/policy/rule?type=read" style="cursor:pointer; text-decoration:none !important;">
                                <p class="text-subtitle-1 font-weight-bold mb-4 mr-3 primary--text">
                                    약관보기
                                    <v-icon class="ml-1 mb-1" color="primary">
                                        mdi-open-in-new
                                    </v-icon>
                                </p>
                            </router-link>
                        </v-sheet>
                        <v-sheet class="d-flex mx-14">
                            <v-checkbox
                                style="padding-top:3px !important"
                                class="ma-0 pa-0"
                                color="primary"
                                v-model="privacy"
                                :error-messages="privacy_Errors"
                                label="개인정보처리방침"
                                required
                                @change="$v.privacy.$touch()"
                                @blur="$v.privacy.$touch()"
                            ></v-checkbox>
                            <v-spacer></v-spacer>
                            <router-link to="/policy/privacy?type=read" style="cursor:pointer; text-decoration:none !important;">
                                <p class="text-subtitle-1 font-weight-bold mb-4 mr-3 primary--text">
                                    약관보기
                                    <v-icon class="ml-1 mb-1" color="primary">
                                        mdi-open-in-new
                                    </v-icon>
                                </p>
                            </router-link>
                        </v-sheet>
                    </v-card-text>
                    <v-sheet class="d-flex justify-center">
                        <v-btn
                            class="font-weight-bold rounded-10"
                            style="font-size:18px;"
                            color="#615DFA"
                            width="173"
                            height="56"
                            dark
                            depressed
                            @click="submit()"
                        >
                            진행하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="px-6"
        >
            <!-- 상단 -->
            <div class="d-flex align-center">
                <!-- 제목 -->
                <div
                    style="cursor:pointer;" 
                    @click="$router.push('/mypage/home')"
                >
                    <p
                        class="mt-3"
                        style="font-size:16px; font-weight:bold; margin-bottom:10px;"
                    >
                        회원가입
                    </p>
                </div>
            </div>

            <!-- 본문 -->
            <v-sheet class="py-8 rounded-15">
                <v-sheet
                    class="mx-auto"
                >
                    <v-img
                        class="mx-auto"
                        :src="require('@/assets/logos/vertical_black.svg')"
                        contain
                        width="90"
                    />
                    <p class="mt-5 text-center" style="font-size:12px;">
                        포켓몬카드게임 한국 커뮤니티<br/>
                        '너로정했다(너정다)'에<br/>
                        오신 것을 진심으로 환영합니다
                    </p>
                    <p
                        class="mt-8 text-center font-weight-bold"
                        style="color:#615DFA; font-size:12px;"
                    >
                        필수 이용약관 확인 후 동의해주시기 바랍니다
                    </p>
                    <v-card-text class="mt-2 px-5">
                        <v-sheet class="d-flex" style="margin-bottom:-12px;">
                            <v-checkbox
                                class="ma-0 pa-0"
                                color="primary"
                                dense
                                v-model="rule"
                                :error-messages="rule_Errors"
                                label="너정다 이용약관"
                                required
                                @change="$v.rule.$touch()"
                                @blur="$v.rule.$touch()"
                            ></v-checkbox>
                            <v-spacer></v-spacer>
                            <router-link to="/policy/rule?type=read" style="cursor:pointer; text-decoration:none !important;">
                                <p class="font-weight-bold mt-1px mr-3 mb-0 primary--text">
                                    약관보기
                                    <v-icon class="ml-1" size="20" color="primary">
                                        mdi-open-in-new
                                    </v-icon>
                                </p>
                            </router-link>
                        </v-sheet>
                        <v-sheet class="d-flex">
                            <v-checkbox
                                class="ma-0 pa-0"
                                color="primary"
                                dense
                                v-model="privacy"
                                :error-messages="privacy_Errors"
                                label="개인정보처리방침"
                                required
                                @change="$v.privacy.$touch()"
                                @blur="$v.privacy.$touch()"
                            ></v-checkbox>
                            <v-spacer></v-spacer>
                            <router-link to="/policy/privacy?type=read" style="cursor:pointer; text-decoration:none !important;">
                                <p class="font-weight-bold mt-1px mr-3 mb-0 primary--text">
                                    약관보기
                                    <v-icon class="ml-1" size="20" color="primary">
                                        mdi-open-in-new
                                    </v-icon>
                                </p>
                            </router-link>
                        </v-sheet>
                    </v-card-text>
                    <v-sheet class="d-flex justify-center">
                        <v-btn
                            class="font-weight-bold rounded-10"
                            style="font-size:13px;"
                            color="#615DFA"
                            width="90"
                            height="30"
                            dark
                            small
                            depressed
                            @click="submit()"
                        >
                            간편가입
                        </v-btn>
                    </v-sheet>

                    <p
                        class="mt-6px mb-0"
                        style="font-size:10px; color:#23D2E2; text-align:center;"
                    >
                        아이디/닉네임/이메일만 입력하면 가입완료!
                    </p>
                </v-sheet>
            </v-sheet>

<!--            <p
                style="font-size:12px; font-weight:bold; text-align:center; margin:10px 0; margin-top:20px;"
            >
                소셜 계정으로 가입하기
            </p>

            <v-sheet
                class="rounded-15"
            >
                <v-card-text class="d-flex justify-center rounded-15">
                    <v-btn
                        small
                        color="#2DB400"
                        depressed
                        dark
                        fab
                        @click="$buttonAlert('준비중입니다.')"
                    >
                        <v-icon x-large>mdi-alpha-n</v-icon>
                    </v-btn>
                    <v-btn
                        small
                        color="#F7E600"
                        depressed
                        dark
                        class="mx-10"
                        fab
                        @click="$buttonAlert('준비중입니다.')"
                    >
                        <v-icon>mdi-chat</v-icon>
                    </v-btn>
                    <v-btn
                        small
                        color="#4285F4"
                        depressed
                        dark
                        fab
                        @click="$buttonAlert('준비중입니다.')"
                    >
                        <v-icon>mdi-google</v-icon>
                    </v-btn>
                </v-card-text>
            </v-sheet>-->
        </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'

export default {
    mixins: [validationMixin],

    validations: {
        rule: {
            checked (val) {
                return val
            }
        },

        privacy: {
            checked (val) {
                return val
            }
        }
    },

    data: () => ({
        rule:false,
        privacy:false,
        submitStatus: null
    }),

    computed: {
        rule_Errors () {
            const errors = []
            if (!this.$v.rule.$dirty) return errors
            !this.$v.rule.checked && errors.push('이용약관에 동의해주세요')
            return errors
        },
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보처리방침에 동의해주세요')
            return errors
        }
    },

    methods: {
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                setTimeout(() => {
                this.submitStatus = 'OK'
                }, 500)
                this.$router.push('/auth/joinform')
            }
        }
    }
}
</script>
<style>
  .v-input--checkbox .v-label {
    font-size: 15px;
    font-weight: bold;
  }
</style>