<template>
    <v-card class="pb-16" outlined>
        <!-- 제목 -->
        <p class="pt-6 font-weight-bold text-center text-h5 grey--text text--darken-2">비밀번호 찾기</p>
        <v-divider class="mx-4"></v-divider>
        
        <!-- 본문 -->
        <v-sheet class="mt-10">
            <v-card
                outlined
                :width="$vuetify.breakpoint.mobile? '90%' : 460"
                class="mx-auto pb-6"
            >
                <!-- 로고 -->
                <v-img
                    class="mx-auto mt-10"
                    :src="require('@/assets/logos/vertical_black.svg')"
                    contain
                    :width="$vuetify.breakpoint.mobile? 90 : 180"
                />
                
                <!-- 입력 -->
                <v-card-text>
                    <v-text-field
                        class="mx-14"
                        outlined
                        flat
                        dense
                        v-model="email"
                        hide-details
                        color="blue"
                        placeholder="이메일"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>
                    <p class="text-caption text-center mt-2 mb-0 mx-8">
                        가입 시 입력하신 이메일로 임시 비밀번호 발송해드립니다
                    </p>
                </v-card-text>

                <!-- 발송 -->
                <v-btn
                    class="font-weight-bold d-flex mx-auto rounded-10"
                    color="primary"
                    dark
                    width="60%"
                    large
                    depressed
                    @click="submit()"
                >
                    이메일 발송
                </v-btn>

                <!-- 버튼 -->
                <v-card-text class="d-flex justify-center">
                    <v-btn to="/auth/login" small text color="grey darken-1" class="mr-1">로그인으로 돌아가기</v-btn>
                    <v-btn to="/auth/findid" small text color="grey darken-1">아이디 찾기</v-btn>
                </v-card-text>
            </v-card>
        </v-sheet>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        email: ""
    }),

    methods: {
        submit(){
            // 이메일로 회원 찾기
            this.$http.post('/api/auth/find_password/search', {
                params: {
                    email: this.email
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("해당 이메일로 가입된 회원이 없습니다.")
                }else{
                    // 이메일 발송
                    this.$http.post('/api/auth/find_password/send', {
                        params: {
                            email: this.email,
                            user_id: res.data[0].user_id
                        }
                    }).then((res) => {
                        if(res.data){
                            alert("임시 비멀번호 이메일이 정상적으로 발송되었습니다.")
                            this.$router.push('/auth/login')
                        }
                    })
                }
            })
        }
    }
}
</script>